
import {Component, ProvideReactive, Watch} from 'vue-property-decorator'
import {ApiResource, MaintenanceSystemsResource, MigrationStatusResource, PropertyMaintenanceSystemsResource, PropertyResource} from '@/resources'
import { BasePropertyComponent } from '../base/BaseMixins'
import MaintenanceItemPrototypeSearch from "@/components/maintenanceSystems/MaintenanceItemPrototypeSearch.vue"
import PropertyMaintenanceItemsPanel from "@/components/properties/PropertyMaintenanceItemsPanel.vue";
import PropertyMaintenanceSystemFormDialog from "@/components/properties/PropertyMaintenanceSystemFormDialog.vue"
import BaseResourceProperty from '../base/BaseResourceProperty.vue';
import BaseResourcePropertyTooltip from '../base/BaseResourcePropertyTooltip.vue';

@Component({components: {BaseResourceProperty, BaseResourcePropertyTooltip, MaintenanceItemPrototypeSearch, PropertyMaintenanceSystemFormDialog, PropertyMaintenanceItemsPanel}})
export default class PropertyAncientHistory extends BasePropertyComponent {

  @ProvideReactive() propertyResource : PropertyResource = this.resource

  panelIdx : number = 0
  showHistory : boolean = false

  addingSystem : boolean = false

  addMenu : boolean = false
  addMenu2 : boolean = false
  wtMenu : boolean = false
  addMenu_x : number = 0
  addMenu_y : number = 0
  addPanelProps : any = {}

  taskCount : number = 0
  uvDone : boolean = false
  filtersDone : boolean = false
  lampsDone : boolean = false
  alertsDone : boolean = false
  waterInfoDone : boolean = false
  roDone : boolean = false
  softenerDone : boolean = false
  oldHistoryFound : boolean = false
  navUnfinished : boolean = true
  
  ms !: MigrationStatusResource 
  msLoaded : boolean = false

  allIds : string[] = []
  stats : any = {}
  pidx : number = -1

  wtSystem : MaintenanceSystemsResource | null = null
  uvSystem : MaintenanceSystemsResource | null = null
  roSystem : MaintenanceSystemsResource | null = null
  soSystem : MaintenanceSystemsResource | null = null
  preUvSystem : MaintenanceSystemsResource | null = null
  wellSystem : MaintenanceSystemsResource | null = null
  rwSystem : MaintenanceSystemsResource | null = null
  wsSystem : MaintenanceSystemsResource | null = null

  systemDialog : boolean = false
  p_wtSystem : PropertyMaintenanceSystemsResource | null = null
  p_uvSystem : PropertyMaintenanceSystemsResource | null = null
  p_roSystem : PropertyMaintenanceSystemsResource | null = null
  p_soSystem : PropertyMaintenanceSystemsResource | null = null
  p_preUvSystem : PropertyMaintenanceSystemsResource | null = null
  p_wellSystem : PropertyMaintenanceSystemsResource | null = null
  p_rwSystem : PropertyMaintenanceSystemsResource | null = null
  p_wsSystem : PropertyMaintenanceSystemsResource | null = null

  selectedPms : PropertyMaintenanceSystemsResource | null = null
  selectedMs : MaintenanceSystemsResource | null = null

  pmsLoading : boolean = false

  get pUtil() {
    return PropertyResource
  }

  @Watch("showHistory", {immediate: true})
  showHistoryChanged() {
    if (!this.showHistory) {
      this.addMenu = false
      this.addMenu2 = false
      this.wtMenu = false
    }
  }

  async addSystem(ms : MaintenanceSystemsResource) {
    this.addingSystem = true
    try {
      let newPmsData = {maintenanceSystem : ms.uriFull, property: this.resource.uriFull}
      let state = await new PropertyMaintenanceSystemsResource().post(newPmsData)
      let newPms = new PropertyMaintenanceSystemsResource(state.data.id + "?projection=propertyMaintenanceSystemSummary")
      await newPms.get()
      this.selectedPms = newPms

      this.setPmsSystem(newPms)
    }
    catch (e : any) {
      console.error(e)
      alert(e)
    }
    finally {
      this.addingSystem = false
    }
  }

  setAddMenuLocation(evt : any) {
    evt.preventDefault()
    let bounds = evt.target.parentElement.getBoundingClientRect()
    this.addMenu_x = bounds.x
    this.addMenu_y = bounds.y
  }

  @Watch("panelIdx")
  panelChanged() {
    this.addMenu = false
    this.addMenu2 = false
    this.wtMenu = false
    if (this.panelIdx === undefined) {

      return
    }
    switch (this.panelIdx) {
      case 0: 
        this.showWtMenu()
      break;
      
      case 1: 
      // @ts-ignore
        this.showAddMenu(this.uvSystem)
      break;
      
      case 2: 
      // @ts-ignore
        this.showAddMenu(this.preUvSystem)
        break;
      
      case 3: 
      // @ts-ignore
        this.showAddMenu(this.soSystem)
        break;
      
      case 4: 
      // @ts-ignore
        this.showAddMenu(this.roSystem)
        break;
      
      case 5: 
      // @ts-ignore
        this.showAddMenu(this.wsSystem)
        break;
    }
  }

  showWtMenu() {
    this.$nextTick(() => {
          this.wtMenu = true
    })
    // see if pms exists
    //@ts-ignore
    this.findPrimaryPms(this.wtSystem).then(s => {
      this.p_wtSystem = new PropertyMaintenanceSystemsResource(s.data().id + "?projection=propertyMaintenanceSystemSummary")
    })
    .catch(() => {})
  }

  showAddMenu(ms : MaintenanceSystemsResource) {

    // well and rw done together
    if (ms.data().name == "Water source") {

      this.$nextTick(() => {
          this.addMenu2 = true
      })

      // see if pms exists
      //@ts-ignore
      this.findPrimaryPms(this.rwSystem).then(s => {
        this.p_rwSystem = new PropertyMaintenanceSystemsResource(s.data().id + "?projection=propertyMaintenanceSystemSummary")
      })
      .catch(() => {})
      //@ts-ignore
      this.findPrimaryPms(this.wellSystem).then(s => {
        this.p_wellSystem = new PropertyMaintenanceSystemsResource(s.data().id + "?projection=propertyMaintenanceSystemSummary")
      })
      .catch(() => {})
    }
    else {
      this.selectedMs = ms
      this.$nextTick(() => {
          this.addMenu = true
      })

      // see if pms exists
      this.findPrimaryPms(this.selectedMs).then(s => {
        this.selectedPms = new PropertyMaintenanceSystemsResource(s.data().id + "?projection=propertyMaintenanceSystemSummary")
      })
      .catch(() => {this.selectedPms=null})
    }
  }

  created() {
    new MigrationStatusResource("property/ids").get().then(s => {
      this.allIds = [...s.data]
    })

    // get ms systems
    ApiResource.Instance.maintenanceSystems.getAssociation().then(mms => {
      mms.forEach(m => {
        let name = m.data().name
        switch (name) {
          case "Water Treatment":
            this.wtSystem = m
            break
          case "UV":
            this.uvSystem = m
            break
          case "Pre UV Filtration":
            this.preUvSystem = m;
            break
          case "Reverse Osmosis":
            this.roSystem = m
            break
          case "Conditioner":
            this.soSystem = m;
            break
          case "Water source":
            this.wsSystem = m;
            break
          case "Well":
            this.wellSystem = m;
            break
          case "Rain Water Catchment":
            this.rwSystem = m;
            break
        }
      })
    })

    this.fetchStats()
  }

  fetchStats() {
    new MigrationStatusResource("stats").get(false).then(s => {
      this.stats = {...s.data}
    })
  }

  findPrimaryPms(ms : MaintenanceSystemsResource) : Promise<PropertyMaintenanceSystemsResource> {
    return new PropertyMaintenanceSystemsResource().searchPrimarySystem.getAssociation({projection: "propertyMaintenanceSystemSummary", pid: this.resource.data().id, msid: ms.data().id}, false)
  }

  postInitialLoad() {

    this.propertyResource = this.resource
    
    if (this.history && this.history.recordNum) {
      this.ms = new MigrationStatusResource(this.history.recordNum)

      this.refreshPMS()

      this.ms.get().then((state) => {

        let data = state.data
        let history = this.history

        this.uvDone = data.uvDone
        this.lampsDone = data.lampsDone
        this.filtersDone = data.filtersDone
        this.alertsDone = data.alertsDone
        this.waterInfoDone = data.waterInfoDone
        this.roDone = data.roDone
        this.softenerDone = data.softenerDone

        // calc task Count
        this.taskCount = 0
        if ((history.uvItems || history.lamps) && !this.uvDone && !this.lampsDone) { this.taskCount++} 
        if ((history.filters || history.filtersMediaEtc) && !this.filtersDone) {this.taskCount++}
        if (history.alerts && !this.alertsDone) {this.taskCount++} 
        if (history.waterInfo && !this.waterInfoDone) {this.taskCount++} 
        if (history.ro && !this.roDone) {this.taskCount++} 
        if (history.softener && !this.softenerDone) {this.taskCount++} 

        // clear previous and load systems
        this.p_uvSystem = null
        this.p_roSystem = null
        this.p_soSystem = null
        this.p_preUvSystem = null
        this.p_wellSystem = null
        this.p_rwSystem = null
        this.p_wsSystem = null
 
        this.oldHistoryFound = true
        this.msLoaded = true
      })

      // locate the ids of this property
      this.pidx = this.allIds.findIndex(id => id == this.rdata.id)
    }
    else {
      this.oldHistoryFound = false
    }
  }

  refreshPMS() {
    this.pmsLoading = true
        this.resource.maintenanceSystems.getAssociation({projection:"propertyMaintenanceSystemSummary"}, false).then((rs) => {
          for (let r of rs) {
            this.setPmsSystem(r)
          }
        })
        .finally(() => this.pmsLoading = false)
  }

  setPmsSystem(r : PropertyMaintenanceSystemsResource) {
    let name = r.data().maintenanceSystemName
    switch (name) {
      case "Water Treatment":
        this.p_wtSystem = r
        break
      case "UV":
        this.p_uvSystem = r
        break
      case "Pre UV Filtration":
        this.p_preUvSystem = r
        break
      case "Reverse Osmosis":
        this.p_roSystem = r
        break
      case "Conditioner":
        this.p_soSystem = r
        break
      case "Water source":
        this.p_wsSystem = r
        break
      case "Well":
        this.p_wellSystem = r
        break
      case "Rain water catchment":
        this.p_rwSystem = r
        break
    }
  }

  get history() {
    return this.rdata.ancientHistory
  }

  
  formatItem(item : any) {
      var str = ""
      if (Array.isArray(item)) {
        for (var i=0; i < item.length; i++) {
          str = str + this.formatItem(item[i])
        }
      }
      else if (typeof item === 'string') {
        str = str + item
      }
      else if (typeof item === 'object') {
        for (const key in item) {
          var label = key.replace(/([A-Z])/g, " $1");
          label = label.charAt(0).toUpperCase() + label.slice(1);
          str = str + '<span class="caption font-weight-bold">' + label + '</span>';
          str = str + '<p>' + item[key] + '</p>'
        }
      }
      else {
        str = item
      }
      return str
    }

    updateStatus(patch : any) {
      this.ms.mergePatch(patch).then(() => {
        this.postEntityUpdate()
        this.fetchStats()
      }).catch((err : any) => {
        console.error(err)
      }).finally(() => {
        
      })
    }

    updateAlerts() {
      this.updateStatus({ alertsDone : this.alertsDone })
    }

    updateUv() {
      // lamps are bundled in with UV
      this.lampsDone = this.uvDone
      this.updateStatus({ lampsDone : this.lampsDone, uvDone : this.uvDone })
    }

    updateFilters() {
      this.updateStatus({ filtersDone : this.filtersDone })
    }

    updateWaterInfo() {
      this.updateStatus({ waterInfoDone : this.waterInfoDone })
    }

    updateSoftener() {
      this.updateStatus({ softenerDone : this.softenerDone })
    }

    updateRO() {
      this.updateStatus({ roDone : this.roDone })
    }

    arrowKeyListener(e: KeyboardEvent) {
      if (this.showHistory) {
        this.addMenu = false
        this.addMenu2 = false
        this.wtMenu = false
        e.stopPropagation()

        if (e.code === 'ArrowLeft') {

            if (this.navUnfinished) {
              let mid = this.ms.data().id
              new MigrationStatusResource("prev/" + mid).get(false).then(s => {

                this.selectedMs = null
                this.selectedPms = null

                this.$router.push({name: 'property', params: {pid: s.data.pid}})
              })
            }
            else {
              if (this.pidx == 0) {
                this.pidx = this.allIds.length-1
              }
              else {
                this.pidx--
              }

              this.selectedMs = null
              this.selectedPms = null

              this.$router.push({name: 'property', params: {pid: this.allIds[this.pidx]}})
            }
        } else if (e.code === 'ArrowRight') {

            if (this.navUnfinished) {
              let mid = this.ms.data().id
              new MigrationStatusResource("next/" + mid).get(false).then(s => {

                this.selectedMs = null
                this.selectedPms = null
                this.$router.push({name: 'property', params: {pid: s.data.pid}})
              })
            }
            else {
              if (this.pidx == this.allIds.length-1) {
                this.pidx = 0
              }
              else {
                this.pidx++
              }

              this.selectedMs = null
              this.selectedPms = null
              this.$router.push({name: 'property', params: {pid: this.allIds[this.pidx]}})
            }
        }
      }
    }

    mounted() {
      window.removeEventListener('keyup', this.arrowKeyListener);
      window.addEventListener('keyup', this.arrowKeyListener);
    }
    beforeUnmount() { // vue3
      window.removeEventListener('keyup', this.arrowKeyListener)
    }
    beforeDestroy() {  // vue2
     window.removeEventListener('keyup', this.arrowKeyListener)
    }
}

