
import {Component, ProvideReactive} from 'vue-property-decorator'
import { BasePropertyComponent } from '../base/BaseMixins'
import { PropertyResource } from '@/resources'
import PropertyAncientHistory from "@/components/properties/PropertyAncientHistory.vue"

@Component({components: {PropertyAncientHistory}})
export default class PropertyView extends BasePropertyComponent {
  @ProvideReactive() propertyResource : PropertyResource = this.resource

}
