
import {Component, Prop} from 'vue-property-decorator'
import { BaseEntityResourceComponent } from '@/components/base/BaseMixins'

@Component({components: {}})
export default class BaseResourcePropertyTooltip extends BaseEntityResourceComponent {
  @Prop({required: true}) readonly property !: string
  @Prop({default:200}) readonly maxWidth !: any
  @Prop({default:'mdi-information-outline'}) readonly icon !: any
}
